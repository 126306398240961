'use client';

import { useEffect, useState } from 'react';
import Link from 'next/link';
import { Button } from '@nextui-org/button';
import { Input } from '@nextui-org/input';
import { Modal, useDisclosure } from '@nextui-org/modal';
import { Formik } from 'formik';
import { ExternalLink, Eye, EyeOff } from 'lucide-react';
import { signIn } from 'next-auth/react';
import { toast } from 'sonner';
import * as Yup from 'yup';
import { useLoaderStore } from '@/src/lib/stores/generic-loader/loader-store';
import ForgotPassword from '@/components/shared/client/auth/forgot-password';
import useGeolocation from '@/lib/hooks/use-geolocation';
import usePermission from '@/lib/hooks/usePermission';
import { CommonValidationMessages, PasswordMatch, UserNameMatch } from '@/lib/utils/validation-utils';
const signInSchema = Yup.object().shape({
  email: Yup.string().test('email', 'El correo electrónico o nombre de usuario no es válido.', value => {
    if (!value) return false;
    return Yup.string().email().isValidSync(value) || UserNameMatch.REGEX_PATTERN.test(value);
  }).max(256, CommonValidationMessages.maxLength(256, 'el correo electrónico o nombre de usuario,')).required(CommonValidationMessages.isRequiredToEnter('el correo electrónico o nombre de usuario,')).label('Correo electrónico'),
  password: Yup.string().required(CommonValidationMessages.isRequiredToEnter('la contraseña')).max(20, CommonValidationMessages.maxLength(20, 'la contraseña')).min(8, CommonValidationMessages.minLength(8, 'la contraseña')).matches(PasswordMatch.REGEX_PATTERN, PasswordMatch.MESSAGE).label('Contraseña')
});
export default function AuthForm() {
  const {
    error,
    position: geoLocationPosition
  } = useGeolocation();
  const geolocationStatus = usePermission('geolocation');
  const [permissionGranted, setPermissionGranted] = useState<boolean>(true);
  const [isVisiblePassword, setIsVisiblePassword] = useState(false);
  const toggleVisibility = () => setIsVisiblePassword(!isVisiblePassword);
  const setIsLoadingPage = useLoaderStore(state => state.setLoading);
  const submit = async (values: {
    email: string;
    password: string;
  }) => {
    try {
      await signIn('credentials', {
        callbackUrl: '/',
        email: values.email,
        password: values.password,
        redirect: true
      });
    } catch (error) {}
  };
  const ForgotPasswordDialog = useDisclosure();
  useEffect(() => {
    if (geolocationStatus === 'granted') {
      setPermissionGranted(true);
      return;
    }
    if (geolocationStatus === 'denied') {
      toast.warning(<div className="flex flex-col gap-2">
          <p className="font-semibold">Es necesario habilitar la ubicación para acceder a la aplicación.</p>
          <Link className="inline-flex items-center gap-2 text-sm font-semibold text-blue-500 underline" href={'https://support.google.com/chrome/answer/142065?hl=es-419&co=GENIE.Platform%3DDesktop&oco=0'} target="_blank">
            Tutorial para habilitar la ubicación
            <ExternalLink size={16} />
          </Link>
        </div>, {
        closeButton: true,
        duration: 300000
      });
      setPermissionGranted(false);
      return;
    }
  }, [geolocationStatus]);
  return <Formik initialValues={{
    email: '',
    password: ''
  }} onSubmit={async (values, formikHelpers) => {
    setIsLoadingPage(true, 'Iniciando Sesión...');
    await submit(values);
    formikHelpers.setSubmitting(false);
  }} validationSchema={signInSchema} data-sentry-element="Formik" data-sentry-component="AuthForm" data-sentry-source-file="form.tsx">
      {({
      errors,
      handleBlur,
      handleChange,
      handleSubmit,
      isSubmitting,
      isValid,
      values
    }) => <form className={'flex w-full flex-col gap-4'} onSubmit={handleSubmit}>
          <Input aria-label={'User account email'} autoComplete={'username'} className={'w-full'} errorMessage={errors.email} isInvalid={!!errors.email} label="Correo electrónico o nombre de usuario" name={'email'} onBlur={handleBlur} onChange={handleChange} type="text" value={values.email} />
          <Input aria-label={'User account password'} autoComplete={'current-password'} className={'w-full'} endContent={<button className="focus:outline-none" onClick={toggleVisibility} type="button">
                {isVisiblePassword ? <Eye className="text-2xl text-default-400" /> : <EyeOff className="text-2xl text-default-400" />}
              </button>} errorMessage={errors.password} isClearable isInvalid={!!errors.password} label="Contraseña" name={'password'} onBlur={handleBlur} onChange={handleChange} type={isVisiblePassword ? 'text' : 'password'} value={values.password} />
          <div className={'grid w-full grid-cols-2 gap-3'}>
            <>
              <Button className="md:text-vase text-tiny" isDisabled={isSubmitting} onPress={() => ForgotPasswordDialog.onOpen()} variant={'solid'}>
                Restablecer contraseña
              </Button>
              <Modal backdrop="opaque" isOpen={ForgotPasswordDialog.isOpen} onClose={ForgotPasswordDialog.onClose} placement="top-center" size="sm">
                <ForgotPassword></ForgotPassword>
              </Modal>
            </>

            <Button className="md:text-vase text-tiny" color={'primary'} isDisabled={!isValid || !permissionGranted} isLoading={isSubmitting} type={'submit'} variant={'solid'}>
              Iniciar sesión
            </Button>
          </div>
          {!permissionGranted && <p className="text-tiny text-content1-foreground/55">
              <span className="font-bold">Nota:</span> Es necesario habilitar la localización para iniciar sesión.
            </p>}
        </form>}
    </Formik>;
}